import React from 'react'
import ContactMobile from './contactMobile'
import ContactDesktop from './contactDesktop'
import window from "window-or-global"


class Contact extends React.Component {

	constructor() {
		super();
		
		this.state = {
			mobileDevice: true,
			mobileCheck: false,
		}
	}

	componentDidMount() {
		if (!this.state.mobileChecked) {
			this.setState({mobileChecked: true});
		}
		this.setState({mobileDevice: this.isMobileDevice()});
	}
	
	isMobileDevice() {
		
		return (window !== "undefined") && (typeof window.orientation !== "undefined");
	};
	

	render() {

		// const mobileDevice = this.isMobileDevice();
		const { contact } = this.props;
		
		
		return(
				<>
				{this.state.mobileDevice && this.state.mobileChecked &&
					<div className="contact">
						<ContactMobile contact={contact} /> 
					</div>
				}
				
				{!this.state.mobileDevice && this.state.mobileChecked &&
					<div className="contact">
						<ContactDesktop contact={contact} /> 			
					</div>
				}
				</>
			
		)

		
	}
}
export default Contact;
