import React from 'react'
import { FaFacebookMessenger, FaWhatsapp, FaInstagram, FaFacebook, FaMapMarkerAlt } from 'react-icons/fa';
import { IoMdClose, IoMdShare } from "react-icons/io";

import { MdLocalPhone } from "react-icons/md";

const ClickOutHandler = require("react-onclickout");

function raiseInvoiceClicked ( url ) {
  window.open(url, '_blank')
}

function callTel ( telephoneNumber ) {
	window.location.href = "tel:"+ telephoneNumber
}


class FloatingMenuItem extends React.Component {

	handleClick() {
		this.props.action();
	}
	
	render() {
		
		let label;
		
		if (this.props.label) {
			label = <label>{this.props.label}</label>;
		}
		
		return <div onClick={this.handleClick.bind(this)}>

					{label}

					{this.props.mainIcon && 
						<div className="floating-menu-item-main">
							<div className="floating-menu-icon-main">
								<div className="floating-menu-icon-main-fa">
									{this.props.icon}
								</div>
							</div>
						</div>
					}

					{!this.props.mainIcon && 
						<div className="floating-menu-item">
							<div className="floating-menu-icon">
								<div className="floating-menu-icon-fa">
									{this.props.icon}
								</div>
							</div>
						</div>
					}
					
			  </div>;
	}
}

class ContactMobile extends React.Component {
	constructor() {
		super();
		
		this.state = {
			toggled: false
		}
	}
	
	toggleMenu() {
		this.setState({toggled: !this.state.toggled});
	}

	onClickOut() {
		this.setState({toggled: false});
	}

	render() {
		let buttons = [];
		let className = "floating-menu";
		let icon = <IoMdShare/>;
		const { contact } = this.props;
		
		if (this.state.toggled) {
			className += " open";
			icon = <IoMdClose/>;

			{ contact.locationUrl && 
				buttons.push(
				<FloatingMenuItem icon={<FaMapMarkerAlt/>} action={() => raiseInvoiceClicked(contact.locationUrl)} key="i1"/>);
			}
			{ contact.instagramUrl && 
				buttons.push(
				<FloatingMenuItem icon={<FaInstagram/>} action={() => raiseInvoiceClicked(contact.instagramUrl)} key="i2"/>);
			}
			{ contact.facebookUrl && 
				buttons.push(
				<FloatingMenuItem icon={<FaFacebook/>} action={() => raiseInvoiceClicked(contact.facebookUrl)} key="i3"/>);
			}
			{ contact.facebookMessengerUrl &&
				buttons.push(
				<FloatingMenuItem icon={<FaFacebookMessenger/>} action={() => raiseInvoiceClicked(contact.facebookMessengerUrl)} key="i4"/>);
			}
			{ contact.whatsAppUrl && 
				buttons.push(
				<FloatingMenuItem icon={<FaWhatsapp/>} action={() => raiseInvoiceClicked(contact.whatsAppUrl)} key="i5"/>);
			}
			{ contact.telephoneNumber &&
				buttons.push(
				<FloatingMenuItem icon={<MdLocalPhone/>} action={() => callTel(contact.telephoneNumber)} key="i6"/>);
			}		
		}
		
		buttons.push(<FloatingMenuItem icon={icon} mainIcon={true} action={this.toggleMenu.bind(this)} key="m"/>); 
		
		return <div className="container">
					
						<div className={className}>
							<ClickOutHandler onClickOut={this.onClickOut.bind(this)}>
								{buttons}
							</ClickOutHandler>
						</div>
      				
				</div>;
	}
}
export default ContactMobile;
