import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/logo.png'



class Header extends React.Component {

	constructor() {
		super();
		
		this.state = {
			mobileDevice: true,
			mobileCheck: false,
		}
	}

	componentDidMount() {
		if (!this.state.mobileChecked) {
			this.setState({mobileChecked: true});
		}
		this.setState({mobileDevice: this.isMobileDevice()});
	}
	
	isMobileDevice() {
		
		return (window !== "undefined") && (typeof window.orientation !== "undefined");
	};
	

	render() {

		return(
      <div
      style={{
        margin: '0 auto',
        maxWidth: '70rem',
        padding: '1.45rem 1.0875rem'
      }}
      >
        <h1 style={{ margin: 0 }}>
  
          <Link
            to="/"
            style={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            {!this.state.mobileDevice && this.state.mobileChecked &&
              <img src={Logo} style={{ marginBottom: '0.5rem' }} alt='logo'/>
            }

            {this.state.mobileDevice && this.state.mobileChecked  &&
              <img src={Logo} style={{ display: 'block', margin: 'auto auto', marginBottom: '0.5rem' }} alt='logo'/>
            }
  
          </Link>
  
        </h1>
        
        <hr style={{marginBottom: 0}} />
  
      </div>

			
			
		)

		
	}
}
export default Header;