import React from 'react'


function Footer() {
  return (
    <div className="footer">      
      <hr />
      <div> Desenvolvido por <a href="https://cardapfy.com"><span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> Cardapfy 2019</a>. Todos os direitos reservados.</div>
    </div>   
  )
}

export default Footer
