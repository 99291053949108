import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import Contact from './contact'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            contact {
              instagramUrl
              facebookUrl
              facebookMessengerUrl
              whatsAppUrl
              locationUrl
              telephoneNumber
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Cardapio Online' },
            { name: 'viewport', content: 'width=device-width' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        
        <Header siteTitle={data.site.siteMetadata.title} logo={data.site.siteMetadata.logo}/>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '75rem',
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>

        <Footer/>

        <Contact contact={data.site.siteMetadata.contact}/>
    
      </>
    )}
  />
)



export default Layout
