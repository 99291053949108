import React from 'react'
import { FaFacebookMessenger, FaWhatsapp, FaInstagram, FaFacebook, FaMapMarkerAlt } from 'react-icons/fa';

function raiseInvoiceClicked ( url ) {
  window.open(url, '_blank')
}

const ContactDesktop = ({ contact }) => (

    <div className="icon-bar">
      { contact.whatsAppUrl && <a alt="whatsapp" onClick={() => raiseInvoiceClicked(contact.whatsAppUrl)}><FaWhatsapp size={30}/></a> }
      { contact.facebookMessengerUrl && <a alt="facebookMessenger" onClick={() => raiseInvoiceClicked(contact.facebookMessengerUrl)}><FaFacebookMessenger size={30}/></a> }
      { contact.facebookUrl && <a alt="facebook" onClick={() => raiseInvoiceClicked(contact.facebookUrl)}><FaFacebook size={30}/></a> }
      { contact.instagramUrl && <a alt="instagram" alt="instagram" onClick={() => raiseInvoiceClicked(contact.instagramUrl)}><FaInstagram size={30}/></a> }
      { contact.locationUrl && <a alt="location" onClick={() => raiseInvoiceClicked(contact.locationUrl)}><FaMapMarkerAlt size={30}/></a> }
    </div>

)

export default ContactDesktop
